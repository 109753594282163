<template>
  <div>
    <NavbarAdmin />
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style scoped></style>
